import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { BANNER_AD_TIME } from '../services/ABeCProperties'
import adService from './service/adService';
import VizSensor from 'react-visibility-sensor';
import styles from './styles/advertise.module.css';
import banner from '../../assets/banner.svg'

class BannerAd extends Component {

    constructor(props) {
        super(props);
        this.currentAd = {};
        this.nextAd = {};
        this.seconds = 0;
        this.bannerTimer = null;
        this.state = {
            ad: {
                adLink: banner,
            },
            isComponentVisible: false,
            viewed: {},
            ismobile: window.innerWidth <= 1280
        };
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    handleResize = () => {
        this.setState({ ismobile: window.innerWidth <= 1280 });
    };

    componentWillUnmount = () => {
        this.bannerTimer && clearInterval(this.bannerTimer);
        this.updateVisibleAds(false)
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize);
        if (this.props.content_page === "ListingPage" && this.props.search_data)
            this.getListingAds()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.content_page !== prevProps.content_page)
            window.scrollTo(0, 0);
        if (this.props.content_page === "ListingPage") {
            if (this.props.search_data !== prevProps.search_data)
                this.getListingAds()
        } else if (this.props.content_page === "LookupPage") {
            if (this.props.search_data !== prevProps.search_data)
                this.getAdsBySearch()
        } else if (this.props.content_page !== prevProps.content_page) {
            if ((prevProps.content_page === "ListingPage" && this.state.ad.id) || !this.state.ad.id) {
                this.bannerTimer && clearInterval(this.bannerTimer);
                this.updateVisibleAds(false)
                this.setState({
                    ad: { adLink: banner }
                })
            }
            this.getAds()
        }

        const visiblityPropChanged = this.state.isComponentVisible !== prevState.isComponentVisible || this.props.isPageVisible !== prevProps.isPageVisible || this.props.isPopupOpen !== prevProps.isPopupOpen
        if (this.state.ad.id && visiblityPropChanged) {
            if (this.state.isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen)
                this.startTimer()
            else
                this.stopTimer()
        }
    }

    getListingAds = () => {
        this.setState({
            ad: {
                adLink: banner
            }
        }, () => {
            if (this.props.search_data.loadAds)
                adService.getAdsByListing(this.props.search_data, "banner").then(response => {
                    if (this.props.viewedAds.length < 135) {
                        this.showAdvertisement(response)
                        this.updateAdDisplay(this.state.ad.id)
                    }
                })
            else {
                if (this.bannerTimer)
                    this.stopTimer();
                this.currentAd = {};
            }
        })
    }

    getAdsBySearch = () => {
        this.setState({
            ad: {
                adLink: banner
            }
        })
        if (this.props.search_data.loadAds)
            adService.getAdsBySearch(this.props.search_data, 'banner').then(response => {
                if (this.props.viewedAds.length < 135) {
                    this.showAdvertisement(response)
                    this.updateAdDisplay(this.state.ad.id)
                }
            })
        else {
            if (this.bannerTimer)
                this.stopTimer();
            this.currentAd = {};
        }
    }

    getAds = () => {
        this.setState({
            ad: {
                adLink: banner
            }
        })
        adService.getAdvrtsByPageTypeAndLocation(this.props.current_coords, this.props.content_page, 'banner')
            .then(response => {
                if (this.props.viewedAds.length < 135) {
                    this.showAdvertisement(response)
                    this.updateAdDisplay(this.state.ad.id)
                }
            })
    }

    showAdvertisement = (ads) => {
        const storedAd = JSON.parse(sessionStorage.getItem('banner'))

        if (storedAd && storedAd.page === this.props.content_page) {
            this.currentAd = storedAd
            this.setState({ ad: storedAd, viewed: { [storedAd.id]: this.props.viewedAds.includes(storedAd.id + ":banner") } })
            this.seconds = 30;
        } else if (!this.isEmpty(ads[0]) || ads[0]) {
            this.nextAd = ads.splice(0, 1)[0];
            if (this.props.viewedAds.includes(this.nextAd.id + ":banner"))
                this.showAdvertisement(ads) // if next ad is already viewed (works for user own ad)
            else if (this.state.ad.id && this.seconds < BANNER_AD_TIME) {
                this.currentAd = { ...this.nextAd };
                this.currentAd.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd.advertisement;
                this.setState({ ad: this.currentAd })
                this.currentAd.page = this.props.content_page
                sessionStorage.setItem("banner", JSON.stringify(this.currentAd))
                this.seconds = 0;
                this.nextAd = {};
                if (this.state.ad.id === this.nextAd.id)
                    this.showAdvertisement(ads)
                else
                    this.nextAd.adLink = process.env.REACT_APP_IMAGES_URL + this.nextAd.advertisement;
            } else {
                this.currentAd = { ...this.nextAd };
                this.currentAd.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd.advertisement;
                this.setState({ ad: this.currentAd })
                this.currentAd.page = this.props.content_page
                sessionStorage.setItem("banner", JSON.stringify(this.currentAd))
                this.seconds = 0;
                this.nextAd = {};
            }
            (this.state.isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen) && this.startTimer()
        } else if (!this.state.ad.id || this.seconds >= BANNER_AD_TIME) {
            this.setState({
                ad: {
                    adLink: banner
                }
            })
            sessionStorage.removeItem('banner')
        } else {
            this.nextAd = null;
        }
    }

    startTimer = () => {
        if (this.bannerTimer)
            this.stopTimer();
        if (this.currentAd.id && this.seconds <= BANNER_AD_TIME) {
            if (this.nextAd === null) {
                this.currentAd = {}
                this.setState({ ad: { adLink: banner } })
            }
            else this.bannerTimer = setInterval(() => {
                this.seconds += 1;
                if (this.seconds > BANNER_AD_TIME) {
                    this.updateBannerViews(this.state.ad.id);
                    this.stopTimer();
                    // if (this.nextAd.id) {
                    //     this.currentAd = { ...this.nextAd };
                    //     this.setState({ ad: this.currentAd })
                    //     this.currentAd.page = this.props.content_page
                    //     sessionStorage.setItem("banner", JSON.stringify(this.currentAd))
                    //     this.nextAd = {};
                    //     this.seconds = 0;
                    //     this.startTimer();
                    // }
                }
            }, 1000);
        }
    }

    stopTimer = () => {
        clearInterval(this.bannerTimer);
    }

    updateAdDisplay = (id) => {
        let listingData = {
            viewedOnListing: null,
            section: null,
        }
        if (this.props.search_data.listingId) {
            listingData = {
                viewedOnListing: parseInt(this.props.search_data.listingId),
                section: this.props.search_data.place,
            }
        }
        adService.updateAdDisplay(id, 'banner', listingData).then(result => {
            this.setState({ section: { [id]: result } })
        })
    }

    updateBannerViews = (id) => {
        let listingData = {
            viewedOnListing: null,
            viewed: null,
            section: null,
        }
        if (this.props.search_data.listingId) {
            listingData = {
                viewedOnListing: parseInt(this.props.search_data.listingId),
                viewed: this.props.search_data.isViewed,
                section: this.props.search_data.place,
            }
        }
        adService.updateBannerViews(id, 'banner', listingData).then(result => {
            this.setState({ viewed: { [id]: result } })
            this.props.sendViewedAds(id + ":banner");
        })

    }

    updateVisibleAds = (isComponentVisible) => {
        const adName = "banner"
        if (isComponentVisible && !this.props.visibleAds.includes(adName)) {
            this.props.setVisibleAds([...this.props.visibleAds, adName])
        } else if (!isComponentVisible && this.props.visibleAds.includes(adName)) {
            this.props.setVisibleAds(this.props.visibleAds.filter(e => e !== adName))
        }
    }

    render() {
        const { ismobile } = this.state;
        return (
            <VizSensor
                onChange={(isVisible) => {
                    if (this.state.isComponentVisible !== isVisible) {
                        this.setState({ isComponentVisible: isVisible })
                        this.updateVisibleAds(isVisible)
                    }
                }}
                partialVisibility
            // offset={{ top: 350 }}
            >
                <div className={`${this.props.content_page === "LoginPage" ? styles.banner_ad_login : styles.banner_ad} ${ismobile ? "" : styles.resp}`}>
                    <div className={`${styles.ad_position}`}>
                        <strong className="ad_mark">AD</strong>
                    </div>
                    {(!this.isEmpty(this.state.ad) && this.state.ad.mediaType === 'video')
                        ?
                        <video className={`img-fluid ${this.state.ad.website ? 'cursor_pointer' : ''}`}
                            onClick={() => adService.redirect(this.state.ad.website)}
                            autoPlay={true} loop src={this.state.ad.adLink} type="video/mp4" onLoad={this.handleImageLoad} muted>
                        </video>
                        :
                        <img id="banner-ad" className={`img-fluid ${this.state.ad.website ? 'cursor_pointer' : ''}`} loading='lazy' width='1900' height='500'
                            onClick={() => adService.redirect(this.state.ad.website)}
                            src={this.state.ad.adLink} alt="HomeBannerSlide-2" />
                    }
                    <div className={`${styles.ad_position}`}>
                        {(this.state.viewed[this.state.ad.id]) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                    </div>
                </div>
            </VizSensor>
        );
    }
}

const mapStateToProps = state => {
    return {
        content_page: state.content_page,
        isPopupOpen: state.isPopupOpen,
        current_coords: state.current_coords,
        search_data: state.search_data,
        isPageVisible: state.isPageVisible,
        viewedAds: state.viewedAds,
        visibleAds: state.visibleAds,
        is_login: state.is_login,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        isLogin: (is_login) => dispatch({ type: 'IS_LOGIN', is_login: is_login }),
        sendViewedAds: (viewedAds) => dispatch({ type: 'VIEWED_ADS', viewedAds: viewedAds }),
        setVisibleAds: (visibleAds) => dispatch({ type: 'VISIBLE_ADS', visibleAds: visibleAds }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BannerAd));