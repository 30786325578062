import React, { useEffect, useState } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import Slider from "react-slick";
import jsPDF from "jspdf";

export const Downloads = () => {

    const images = [
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image1.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image2.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image3.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image4.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image5.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image6.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image7.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image8.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image9.jpg",
        process.env.REACT_APP_IMAGES_URL + "assets/Downloads/image10.jpg",
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const downloadPDF = async () => {
        const pdf = new jsPDF("landscape");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        for (const [index, img] of images.entries()) {
            await new Promise((resolve) => {
                const imgData = new Image();
                imgData.src = img;
                imgData.onload = () => {
                    const imgWidth = pageWidth;
                    const imgHeight = (imgData.height / imgData.width) * pageWidth;

                    const yOffset = (pageHeight - imgHeight) / 2;

                    if (index > 0) pdf.addPage();
                    pdf.addImage(imgData, "JPEG", 0, yOffset, imgWidth, imgHeight);
                    resolve();
                };
            });
        }

        pdf.save("how-to-earn.pdf");
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const SampleNextArrow = (props) => {
        const { onClick, currentSlide, slideCount } = props;
        return (
            <div
                onClick={onClick}
                style={{
                    display: currentSlide === slideCount - 1 ? "none" : "block",
                    position: "absolute",
                    right: "-60px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    fontSize: "40px",
                    color: "#FF9933",
                    zIndex: 1,
                }}
            >
                <i class="fa fa-arrow-circle-o-right"></i>
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { onClick, currentSlide } = props;
        return (
            <div
                onClick={onClick}
                style={{
                    display: currentSlide === 0 ? "none" : "block",
                    position: "absolute",
                    left: "-60px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    fontSize: "40px",
                    color: "#FF9933",
                    zIndex: 1,
                }}
            >
                <i class="fa fa-arrow-circle-o-left"></i>
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 400,
        cssEase: 'ease-in-out',
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow currentSlide={currentSlide} slideCount={images.length} />,
        prevArrow: <SamplePrevArrow currentSlide={currentSlide} />,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    return (
        <>
            <div className={`${FooterStyle.grid_container} terms_of_service`}>
                <div className="row">
                    <div className="col-12 mt-3 mt-md-0">
                        <h1 className={`${FooterStyle.pageHeading} text-center pb-5`}>High Level Overview</h1>
                        <div className='pb-5 text-center' style={{ width: "70%", margin: "auto", }}>
                            <h2 className={`${FooterStyle.sectionHeading} pt-5`}>How to Set Up Your Online Income with aajubaaju.in?</h2>
                            <video className={`${FooterStyle.advt_video} pb-5`} height="auto" controls style={{ width: "100%" }}>
                                <source src={process.env.REACT_APP_IMAGES_URL + "assets/Downloads/about-ajubaaju-platform.mp4"} type="video/mp4" />
                            </video><br></br><br></br><br></br>
                            <Slider {...settings}>
                                {images.map((img, index) => (
                                    <div key={index}>
                                        <img
                                            src={img}
                                            alt={`Slide ${index}`}
                                            style={{ width: "100%", borderRadius: "10px" }}
                                        />
                                    </div>
                                ))}
                            </Slider><br></br>
                            <button
                                onClick={downloadPDF}
                                style={{
                                    marginTop: "20px",
                                    padding: "10px 20px",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    borderRadius: "5px",
                                    backgroundColor: "#FF9933",
                                    color: "#fff",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                            >
                                Download as PDF
                            </button>
                            <br></br><br></br><br></br>
                            <h2 className={`${FooterStyle.sectionHeading} pt-5`}>12 ways of earnings in AajuBaaju</h2>
                            <video className={`${FooterStyle.advt_video}`} height="auto" controls style={{ width: "100%" }}>
                                <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/How-to-Earn.mp4"} type="video/mp4" />
                            </video><br></br><br></br><br></br>
                            <h2 className={`${FooterStyle.sectionHeading} pt-5`}>How to Earn Money by Watching Advertisements in Computer?</h2>
                            <video className={FooterStyle.advt_video} height="auto" controls style={{ width: "100%" }}>
                                <source src={process.env.REACT_APP_IMAGES_URL + "assets/Downloads/watchAdvertisementsComputer.mp4"} type="video/mp4" />
                            </video><br></br><br></br><br></br>
                            <div className='d-none d-md-block text-center'>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>How to Earn Money by Watching Advertisements in Mobile?</h2>
                                <video className={`${FooterStyle.advt_video}`} height="auto" controls style={{ width: "30%" }}>
                                    <source src={process.env.REACT_APP_IMAGES_URL + "assets/Downloads/watchAdvertisementsMobile.mp4"} type="video/mp4" />
                                </video>
                            </div>
                            <div className='d-block d-md-none text-center'>
                                <h2 className={`${FooterStyle.sectionHeading}`}>How to Earn Money by Watching Advertisements in Mobile?</h2>
                                <video className={`${FooterStyle.advt_video}`} height="auto" controls style={{ width: "100%" }}>
                                    <source src={process.env.REACT_APP_IMAGES_URL + "assets/Downloads/watchAdvertisementsMobile.mp4"} type="video/mp4" />
                                </video>
                            </div>
                            <br></br><br></br><br></br>
                            {/* <h2 className={`${FooterStyle.sectionHeading} pt-5`}>What Are Stages?</h2>
                            <video className={FooterStyle.advt_video} height="auto" controls style={{ width: "100%" }}>
                                <source src={process.env.REACT_APP_IMAGES_URL + "assets/Downloads/aajubaaju-What-are-Stages.mp4"} type="video/mp4" />
                            </video><br></br><br></br><br></br>
                            <h2 className={`${FooterStyle.sectionHeading} pt-5`}>What is Loyalty Earning?</h2>
                            <video className={FooterStyle.advt_video} height="auto" controls style={{ width: "100%" }}>
                                <source src={process.env.REACT_APP_IMAGES_URL + "assets/Downloads/AajuBaaju-Loyalty-Earnings.mp4"} type="video/mp4" />
                            </video><br></br><br></br><br></br> */}
                        </div>
                    </div>
                </div>
            </div><br></br>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard}`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}