import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'

export const Announcements = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={`${FooterStyle.grid_container} terms_of_service`}>
                <div className="row">
                    <section className="col-md-12">
                        <h1 className={`${FooterStyle.pageHeading} text-center`}>Announcements Board:</h1>
                        <ul>
                            <li>
                                <p className={`${FooterStyle.paragraph}`}>aajubaaju.in aims to increase consumer purchasing power through the online advertisement industry. It encourages AajuBaajuians to empower themselves, others, and the country, ultimately working towards the vision of a "developed India." </p>
                                <p className={`${FooterStyle.paragraph}`}>Check this corner to be updated about any changes or updates in the platform.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 20th February 2025</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>Accumulated Earnings Withdrawal for old registrations and Daily Withdrawal for new registrations</p>
                                <p className={`${FooterStyle.paragraph}`}>To help existing AajuBaajuians to get promoted and new AajuBaajuians to experience aajubaaju.in, we have enabled daily withdrawals for all the new PAID REGISTRATIONS from 1st January 2025.</p>
                                <p className={`${FooterStyle.paragraph}`}>Registrations before 1st January 2025 - We request not to CREATE accounts and watch ads in all. Please invite AajuBaajuians who are willing to setup an online income and grow the AajuBaajuian Community. This is an opportunity to those who are seriously looking to earn online using their talents and free time. Share the opportunity and help as many as possible.</p>
                                <p className={`${FooterStyle.paragraph}`}>The following process will be followed:</p>
                                <p className={`${FooterStyle.paragraph}`}>1. If an existing AajuBaajuian gets promoted and withdraws by inviting in monthly membership @Rs.1770 per month, Accumulated earning will be processed after the new AajuBaajuian renews the monthly membership in the next month.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. If an existing AajuBaajuian gets promoted and withdraws by inviting in Half Yearly membership @Rs.7080 every 6 months, Accumulated earning will be processed on the day of withdrawal.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. If an existing AajuBaajuian gets promoted and withdraws by inviting in Annual membership @Rs.10620 every year, Accumulated earning will be processed on the day of withdrawal.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 5th February 2025</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>There will be no minimum withdrawal limit to withdraw the Ad View Earning for the AajuBaajuians who registers directly in Annual Membership Model. This is applicable on the registrations starting 1st January 2025.</p>
                                <p className={`${FooterStyle.paragraph}`}>This means that, AajuBaajuians who registers directly in Annual Membership Model, can withdraw their Ad View Earnings daily starting from the Beginner Stage itself. Ad View Earnings per ad will be based on the stages, i.e., Beginner - 45p, Intermediate - 60p, Master - 75p and Expert - 90p.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>Note:</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>This is applicable only on the direct Annual Registrations registered on or after 1st January 2025.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 30th January 2025</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>AD view withdrawal button - Fifth Phase - Enabled on 3rd February 2025</p>
                                <p className={`${FooterStyle.paragraph}`}>The following AajuBaajuians will be able to withdraw as per their stages,</p>
                                <p className={`${FooterStyle.paragraph}`}>1. AajuBaajuians in All stages whose registration date is on or before 15th March 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. AajuBaajuians in the EXPERT, MASTER or INTERMEDIATE stage and whose registration date is on or after 13th June 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. AajuBaajuians in All stages whose registration date is on or after 6th August 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>Note:</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>1. The dates to enable the AD view earning withdrawal button for AajuBaajuians whose registrations date is from 16th March 2024 to 12th June 2024 will be announced in the upcoming phases.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. The dates to enable the AD view earning withdrawal button for AajuBaajuians in the BEGINNER stage and whose registration date is from 13th June 2024 to 5th August 2024 will be announced in the upcoming phases.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. Content Earning Withdrawal button is active and not disabled for any AajuBaajuian.</p>
                                <p className={`${FooterStyle.paragraph}`}>4. AajuBaajuians in target model membership (offer period AajuBaajuians) will continue to be in the target for 1 year after renewal.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 20th January 2025</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>AD view withdrawal button - Fourth Phase - Enabled on 20th January 2025</p>
                                <p className={`${FooterStyle.paragraph}`}>The following AajuBaajuians will be able to withdraw as per their stages,</p>
                                <p className={`${FooterStyle.paragraph}`}>1. AajuBaajuians in All stages whose registration date is on or before 31st January 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. AajuBaajuians in the EXPERT, MASTER or INTERMEDIATE stage and whose registration date is on or after 13th June 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. AajuBaajuians in All stages whose registration date is on or after 1st September 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>Note:</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>1. The dates to enable the AD view earning withdrawal button for AajuBaajuians whose registrations date is from 1st February 2024 to 12th June 2024 will be announced in the upcoming phases.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. The dates to enable the AD view earning withdrawal button for AajuBaajuians in the BEGINNER stage and whose registration date is from 13th June 2024 to 1st September 2024 will be announced in the upcoming phases.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. Content Earning Withdrawal button is active and not disabled for any AajuBaajuian.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 2nd January 2025</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>PRIVILEGED AAJUBAAJUIAN ACCOUNTS - Paid Memberships before 16th July 2024 - Renew within 15 days of validity expiry to retain the Privileged AajuBaajuian Status.</p>
                                <p className={`${FooterStyle.paragraph}`}>All accounts which are not renewed within 15 days after expiry will lose their privilege status and will be converted to normal AajuBaajuian accounts.</p>
                                <p className={`${FooterStyle.paragraph}`}>The renewal membership for the normal AajuBaajuian accounts is Rs.9000 + GST = Rs.10620. So, any AajuBaajuian who loses the Privilege AajuBaajuian Status due to non-renewal will need to renew for the existing membership price i.e Rs.10620, if they renew after 15 days of validity expiry. The accumulated earning if any will be carried forward after renewal and can be withdrawn as per stages and withdrawal limits applicable. NOTE: The accumulated AD view earnings, for both yearly and half-yearly memberships will NOT become Zero.</p>
                                <p className={`${FooterStyle.paragraph}`}>Importantly, when the AajuBaajuian loses the Privileged AajuBaajuian status, their invitation link also will lose the lifetime Privilege status to offer 33% discount on Annual Membership.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 31st December 2024</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>AD view withdrawal button - Third Phase - Enabled on 1st January 2025</p>
                                <p className={`${FooterStyle.paragraph}`}>The following AajuBaajuians will be able to withdraw as per their stages,</p>
                                <p className={`${FooterStyle.paragraph}`}>1. AajuBaajuians in All stages whose registration date is on or before 31st December 2023.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. AajuBaajuians in the EXPERT, MASTER or INTERMEDIATE stage and whose registration date is on or after 13th June 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. AajuBaajuians in All stages whose registration date is on or after 1st September 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>Note:</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>1. The dates to enable the AD view earning withdrawal button for AajuBaajuians whose registrations date is from 1st January 2024 to 12th June 2024 will be announced in the upcoming phases.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. The dates to enable the AD view earning withdrawal button for AajuBaajuians in the BEGINNER stage and whose registration date is from 13th June 2024 to 1st September 2024 will be announced in the upcoming phases.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. Content Earning Withdrawal button is active and not disabled for any AajuBaajuian.</p>

                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 25th December 2024</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>A "Tutorials" link has been added to the footer links with the required learning materials about the platform. More materials will be added soon.</p>

                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 12th December 2024</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>A month since we were able to enable the first phase of AD view earning withdrawals.</p>
                                <p className={`${FooterStyle.paragraph}`}>Congratulations to all AajuBaajuians who withdrew their AD view earnings by meeting the withdrawal limits in the month of November 2024. It is clear that AajuBaajuians who joined around the same time have had different outcomes—some reached their withdrawals, while others are still on their way. This shows that the platform and opportunities were the same for everyone. Some used the opportunity, some missed it....Remember, time and opportunity wait for no one.</p>
                                <p className={`${FooterStyle.paragraph}`}>We call upon all parent contributors to check on their level 1 AajuBaajuians. If there are any unpaid registrations in your AajuBaajuian community who are eligible to upgrade, inform them to upgrade and be active in their accounts if they would like to continue as an AajuBaajuian.</p>
                                <p className={`${FooterStyle.paragraph}`}>Come join hands to build aajubaaju.in - India ka Apna Website - Ab India Kamayega Digitally - Ab Sab Banenge Digital Udyamee.</p>
                            </li>
                            <li>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 12th December 2024</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>AD view withdrawal button - Second Phase - Enabled on 1st December 2024</p>
                                <p className={`${FooterStyle.paragraph}`}>The following AajuBaajuians will be able to withdraw as per their stages,</p>
                                <p className={`${FooterStyle.paragraph}`}>1. AajuBaajuians in All stages whose registration date is on or before 30th November 2023.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. AajuBaajuians in the EXPERT, MASTER or INTERMEDIATE stage and whose registration date is on or after 13th June 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. AajuBaajuians in All stages  whose registration date is on or after 1st September 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}><strong>NOTE:</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>1. The dates to enable the AD view earning withdrawal button for AajuBaajuians whose registrations date is from 1st December 2023 to 12th June 2024 will be announced in the upcoming phases.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. The dates to enable the AD view earning withdrawal button for AajuBaajuians in the BEGINNER stage and whose registration date is from 13th June 2024 to 1st September 2024 will be announced in the upcoming phases.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. Content Earning Withdrawal button is active and not disabled for any AajuBaajuian.</p>
                            </li>
                            <li>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 12th December 2024</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>ACCOUNT RENEWALS of EXISTING ACCOUNTS:</p>
                                <p className={`${FooterStyle.paragraph}`}>1. The accumulated AD view and Content Earnings, for both yearly and half-yearly memberships will not become Zero.</p>
                                <p className={`${FooterStyle.paragraph}`}>2. The accumulated AD view and Content Earnings, for both yearly and half-yearly memberships will be carried forward after renewal. It can be withdrawn as per stages and withdrawal limits applicable.</p>
                                <p className={`${FooterStyle.paragraph}`}>3. There is no option of UPGRADE to adjust the accumulated AD view and Content earnings in any paid membership model. This is a feature given to postpaid users for the first time to experience aajubaaju.in and cannot be given multiple times. Accounts have to be RENEWED by paying the renewal membership on the renewal date to continue using the aajubaaju.in platform.</p>
                                <p className={`${FooterStyle.paragraph}`}>4. Half year membership models are only for one time. Renewal will be in the Annual Membership only.</p>
                            </li>
                            <li>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 12th December 2024</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>POSTPAID MEMBERSHIP ACCOUNTS VALIDITY:</p>
                                <p className={`${FooterStyle.paragraph}`}>REGISTERED ON OR BEFORE 16th July 2024:</p>
                                <p className={`${FooterStyle.paragraph}`}>The annual membership amount is Rs.7080 to accumulate and upgrade the account. Please note that any AajuBaajuian who does not upgrade within 1 year of validity, will not be able to upgrade with the accumulated Ad view earnings. The accumulated AD view earnings if any, will become ZERO on the end of validity.</p>
                                <p className={`${FooterStyle.paragraph}`}>REGISTERED ON OR AFTER 17th July 2024:</p>
                                <p className={`${FooterStyle.paragraph}`}>The annual membership amount is Rs.10620 to accumulate and upgrade the account. Please note that any AajuBaajuian who does not upgrade within 1 year of validity, will not be able to upgrade with the accumulated Ad view earnings. The accumulated AD view earnings if any, will become ZERO on the end of validity.</p>
                            </li>
                            <li>
                                <p className={`${FooterStyle.paragraph}`}><strong>DATE: 12th December 2024</strong></p>
                                <p className={`${FooterStyle.paragraph}`}>PRIVILEGED AAJUBAAJUIAN ACCOUNTS - Paid Memberships before 16th July 2024 - Renew before 31st December 2024 to retain the Privileged AajuBaajuian Status.</p>
                                <p className={`${FooterStyle.paragraph}`}>All AajuBaajuians whose accounts renewal date is on or before 15th December 2024 and not yet renewed, are requested to renew the accounts before 31st December 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}>A special privilege was given to the AajuBaajuians who registered before 16th July 2024 as a Privileged AajuBaajuian for being early birds to the platform. Though many AajuBaajuian accounts stayed active and showed unwavering support to the platform, some AajuBaajuians became inactive. On verifying, we noticed that accounts were created in the names of family members and one person from the family was watching ads in multiple accounts, which is breach of trust to the advertiser. A person using only their own account was active as they knew that spending 1 hour in aajubaaju.in was not going to affect them in any negative way and they trusted themselves and the platform. Most of the created accounts became inactive as there was no daily withdrawal. Such created accounts cannot be considered as Privileged AajuBaajuians and avail the benefits extended for long term AajuBaajuians.</p>
                                <p className={`${FooterStyle.paragraph}`}>We understand that there was skepticism for renewal as we had to pause the withdrawals due to stagnant memberships. The stagnant memberships was also due to the created accounts as there were no REAL AAJUBAJUIANS to promote the opportunity in aajubaaju.in. Now that the withdrawals are being enabled in phases and the withdrawal limits as per stages is applicable, AajuBaajuians willing to retain the PRIVILEGED AAJUBAAJUIAN status, should renew the expired accounts before 31st December 2024.</p>
                                <p className={`${FooterStyle.paragraph}`}>All accounts which are not renewed will lose their privilege status and will be converted to normal AajuBaajuian accounts. The renewal membership for the normal AajuBaajuian accounts is Rs.9000 + GST = Rs.10620. So, any AajuBaajuian who loses the Privilege AajuBaajuian Status due to non-renewal will need to renew for the existing membership price i.e Rs.10620, if they renew after 31st December 2024. The accumulated earning if any will be carried forward after renewal and can be withdrawn as per stages and withdrawal limits applicable. NOTE: The accumulated AD view earnings, for both yearly and half-yearly memberships will NOT become Zero.</p>
                                <p className={`${FooterStyle.paragraph}`}>Importantly, when the AajuBaajuian loses the Privileged AajuBaajuian status, their invitation link also will lose the lifetime Privilege status to offer 33% discount on Annual Membership.</p>
                            </li>

                        </ul>
                    </section>
                </div>
            </div>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}

