import axios from 'axios';
import authservice from '../../Authentication/service/authservice';

const adUrl = process.env.REACT_APP_BACKEND_URL + '/abmgr/advertisement';

const noAds = {
    largeRectangle: [],
    leaderBoard: [],
    billBoard: [],
    banner: []
}

class AdService {

    async getAdsByPageAndLocation(visitor_address, page) {

        return axios.get(adUrl + `/getAdvertisement/${page}`, {
            params: {
                // state: visitor_address.state,
                // city: visitor_address.city,
                // locality: visitor_address.locality,
                // neighbourhood: visitor_address.neighbourhood
            }
        }).then(response => {
            return response.data
            //this.props.setBillBoardAds(response.data);
        }).then(null, error => {
            console.log("Error : " + error)
        });

    }

    async getAdvrtsByPageAndLocation(visitor_address, page) {
        const userId = authservice.getCurrentUserid();
        return axios.get(adUrl + `/get-ads?page=${page}&latitude=${visitor_address.latitude}&longitude=${visitor_address.longitude}&user=${userId}`)
            .then(response => {
                return response.data
            }).then(null, error => {
                console.log("Error : " + error)
                return noAds;
            });

    }

    //Api for display advertisements for all pages except lookup and listing(previous codes)
    // async getAdvrtsByPageTypeAndLocation(visitor_address, page, type) {

    //     const searchCriteria = {
    //         ...visitor_address,
    //         userId: authservice.getCurrentUserid(),
    //         macId: authservice.getCookie('hp.rocks.soon'),
    //     }
    //     return axios.post(adUrl + `/get-ads/${type}?page=${page}`, searchCriteria)
    //         .then(response => {
    //             return response.data
    //         }).then(null, error => {
    //             console.log("Error : " + error)
    //             return [];
    //         });
    // }

    async getAdvrtsByPageTypeAndLocation(visitor_address, page, type) {
        const searchCriteria = {
            ...visitor_address,
            userId: authservice.getCurrentUserid(),
            macId: authservice.getCookie('hp.rocks.soon'),
            // state: search_data.state,
            // city: search_data.city,
            // locality: search_data.locality,
            // neighbourhood: search_data.neighbourhood,
            visitorState: (localStorage.getItem('state') ? localStorage.getItem('state') : visitor_address.visitorState),
            visitorCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            visitorCountry: visitor_address.visitorCountry,

            category: null,
            subCategory: null,

            userName: authservice.getCurrentUserName(),
        }
        return axios.post(adUrl + `/get-ds-search-input/${type}?page=${page}`, searchCriteria, { withCredentials: true })
            .then(response => {
                return response.data
            }).then(null, error => {
                console.log("Error : " + error)
                return [];
            });
    }

    async getAdsByPageAndSearchCriteria(search_data, page) {

        const searchCriteria = {
            //Visitor's current location
            // visitorState: visitor_address ? visitor_address.state : null,
            // visitorCity: visitor_address ? visitor_address.city : null,
            // visitorLocality: visitor_address ? visitor_address.locality : null,
            // visitorNeighbourhood: visitor_address ? visitor_address.neighbourhood : null,

            //visitor's searched data
            state: search_data.state,
            city: search_data.city,
            locality: search_data.locality,
            neighbourhood: search_data.neighbourhood,

            // category: search_data.category,
            // subCategory: search_data.subCategory,

            userName: authservice.getCurrentUserName(),
        }

        return axios.post(adUrl + `/getadvertisement-by-search/${page}`, searchCriteria).then(response => {
            return response.data
        }).then(null, error => {
            console.log("Error : " + error)
            return noAds;
        });

    }

    //Unused
    async getAdsByNoMatch(search_data, page) {

        var searchCriteria = null;
        try {
            searchCriteria = {
                //Visitor's current location
                // visitorState: search_data.visitorState,
                // visitorCity: search_data.visitorCity,
                // visitorLocality: search_data.visitorLocality,
                // visitorNeighbourhood: search_data.visitorNeighbourhood,

                latitude: search_data.lat,
                longitude: search_data.lon,

                category: search_data.categories,
                subCategory: search_data.subCategories,

                userName: authservice.getCurrentUserName(),
            }
        } catch (error) {
            searchCriteria = {
                category: search_data.categories,
                subCategory: search_data.subCategories,
            }
        }
        const userName = authservice.getCurrentUserName();
        return axios.post(adUrl + `/getads-by-nomatch/${page}/${userName}`, searchCriteria).then(response => {
            return response.data
        }).then(null, error => {
            console.log("Error : " + error)
            return noAds;
        });
    }

    async getAdsByListing(search_data, type) {

        var searchCriteria = null;
        var categories = (localStorage.getItem('categories') ? localStorage.getItem('categories') : null);
        var subCategories = (localStorage.getItem('subCategories') ? localStorage.getItem('subCategories') : null);
        try {
            searchCriteria = {
                latitude: search_data.latitude,
                longitude: search_data.longitude,
                visitorCountry: search_data.visitorCountry,
                //visitorState: search_data.visitorState,
                // category: post_data.categories,
                // subCategory: post_data.subCategories,
                state: search_data.state,
                city: search_data.city,
                locality: search_data.locality,
                neighbourhood: search_data.neighbourhood,
                listingPackage: search_data.listingPackage,
                listingId: search_data.listingId,
                userId: authservice.getCurrentUserid(),
                macId: authservice.getCookie('hp.rocks.soon'),
                visitorState: (localStorage.getItem('state') ? localStorage.getItem('state') : search_data.visitorState),
                visitorCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
                visitorLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
                visitorNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
                // category: (localStorage.getItem('category') ? localStorage.getItem('category') : null),
                // subCategory: (localStorage.getItem('subcategory') ? localStorage.getItem('subcategory') : null),
                category: [categories],
                subCategory: [subCategories],
                //visitorCountry: visitorCountry,
            }
        } catch (error) {
            searchCriteria = {
                category: [categories],
                subCategory: [subCategories],
                visitorCountry: search_data.visitorCountry,
                state: search_data.state,
                city: search_data.city,
                locality: search_data.locality,
                neighbourhood: search_data.neighbourhood,
                visitorState: (localStorage.getItem('state') ? localStorage.getItem('state') : search_data.visitorState),
                visitorCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
                visitorLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
                visitorNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            }
        }

        return axios.post(adUrl + `/getads-by-listing?type=${type}`,
            searchCriteria
        ).then(response => {
            return response.data
        }).then(null, error => {
            console.log("Error : " + error)
            return noAds;
        });
    }

    async getAdsBySearch(search_data, type, visitor_address) {
        var searchCriteria = null;
        try {
            searchCriteria = {
                ...visitor_address,
                ...search_data,
                userId: authservice.getCurrentUserid(),
                location: search_data.location,
                macId: authservice.getCookie('hp.rocks.soon'),
                visitorState: (localStorage.getItem('state') ? localStorage.getItem('state') : search_data.visitorState),
                visitorCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
                visitorLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
                visitorNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            }
        } catch (error) {
            searchCriteria = {
                category: search_data.categories,
                subCategory: search_data.subCategories,
                state: search_data.state,
                city: search_data.city,
                locality: search_data.locality,
            }
        }
        return axios.post(adUrl + `/getads-by-search?type=${type}`,
            searchCriteria
        ).then(response => {
            return response.data
        }).then(null, error => {
            console.log("Error : " + error)
            return noAds;
        });
    }

    async updateViews(adId, type, listingData) {
        const userId = authservice.getCurrentUserid();
        const viewedAdData = {
            adId: adId,
            type: type,
            viewedByUserId: userId,
            guestId: userId ? null : authservice.getCookie('hp.rocks.soon'),
            ...listingData,
        }
        if (adId !== null)
            return axios.post(`${adUrl}/update-views`, viewedAdData).then((response) => {
                if (response)
                    return response.data
            }).catch(error => {
                console.log(error, `update view error : ${JSON.stringify(viewedAdData)}`)
            })
    }
    async updateLbViews(adId, type, listingData) {
        const userId = authservice.getCurrentUserid();
        const viewedAdData = {
            adId: adId,
            type: type,
            viewedByUserId: userId,
            guestId: userId ? null : authservice.getCookie('hp.rocks.soon'),
            ...listingData,
        }
        if (adId !== null) {
            return axios.post(`${adUrl}/update-views-leaderBoard`, viewedAdData).then((response) => {
                if (response)
                    return response.data
            }).catch(error => {
                console.log(error, `update view error : ${JSON.stringify(viewedAdData)}`)
            })
        }
    }
    async updateBannerViews(adId, type, listingData) {
        const userId = authservice.getCurrentUserid();
        const viewedAdData = {
            adId: adId,
            type: type,
            viewedByUserId: userId,
            guestId: userId ? null : authservice.getCookie('hp.rocks.soon'),
            ...listingData,
        }
        if (adId !== null) {
            return axios.post(`${adUrl}/update-views-banner`, viewedAdData).then((response) => {
                if (response)
                    return response.data
            }).catch(error => {
                console.log(error, `update view error : ${JSON.stringify(viewedAdData)}`)
            })
        }
    }
    async updateBbViews(adId, type, listingData) {
        const userId = authservice.getCurrentUserid();
        const viewedAdData = {
            adId: adId,
            type: type,
            viewedByUserId: userId,
            guestId: userId ? null : authservice.getCookie('hp.rocks.soon'),
            ...listingData,
        }
        if (adId !== null) {
            return axios.post(`${adUrl}/update-views-billboard`, viewedAdData).then((response) => {
                if (response)
                    return response.data
            }).catch(error => {
                console.log(error, `update view error : ${JSON.stringify(viewedAdData)}`)
            })
        }
    }
    async updateLrViews(adId, type, listingData) {
        const userId = authservice.getCurrentUserid();
        const viewedAdData = {
            adId: adId,
            type: type,
            viewedByUserId: userId,
            guestId: userId ? null : authservice.getCookie('hp.rocks.soon'),
            ...listingData,
        }
        if (adId !== null) {
            return axios.post(`${adUrl}/update-views-largeractangle`, viewedAdData).then((response) => {
                if (response)
                    return response.data
            }).catch(error => {
                console.log(error, `update view error : ${JSON.stringify(viewedAdData)}`)
            })
        }
    }

    async updateAdDisplay(adId, type, listingData) {
        const userId = authservice.getCurrentUserid();
        const viewedAdData = {
            adId: adId,
            type: type,
            viewedByUserId: userId,
            guestId: userId ? null : authservice.getCookie('hp.rocks.soon'),
            ...listingData,
        }
        if (adId !== null)
            return axios.post(`${adUrl}/update-ad-display-performence`, viewedAdData).then((response) => {
                if (response)
                    return response.data
            }).catch(error => {
                console.log(error, `update view error : ${JSON.stringify(viewedAdData)}`)
            })
    }

    filterAds(ads) {
        let viewedAds = localStorage.getItem('viewed_ads')
        if (viewedAds == null) {
            localStorage.setItem('viewed_ads', new Date());
            return ads;
        } else {
            const viewed = localStorage.getItem('viewed_ads').split(" ");
            let unViewedAds = ads.filter(ad => {
                return !viewed.includes(ad.id.toString());
            })
            return unViewedAds;
        }
    }

    async getViewedAds(user) {
        const userId = user ? user.id : null
        if (userId !== null)
            return axios.get(`${process.env.REACT_APP_BACKEND_URL}/user-activity/get-viewed-ads?userId=${userId}`)
                .then(resp => {
                    return resp.data;
                }).catch(err => {
                    return [];
                })
        else
            return [];
    }

    async getGuestViewedAds(guestId) {
        if (guestId !== null)
            return axios.get(`${process.env.REACT_APP_BACKEND_URL}/guest-activity/get-viewed-ads?guestId=${guestId}`)
                .then(resp => {
                    return resp.data;
                })
        else
            return [];
    }

    redirect(url) {
        if (url)
            window.open(url, "_blank")
    }
}
export default new AdService();