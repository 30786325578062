import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MAX_AD_TIME } from '../services/ABeCProperties'
import adService from './service/adService';
import VizSensor from 'react-visibility-sensor';
import abecPages from '../services/abecPages';
import styles from './styles/advertise.module.css';
import Billboard from '../../assets/Billboard.png'

class BillBoard extends Component {

    constructor(props) {
        super(props);
        this.currentAd = {};
        this.seconds = 0;
        this._isMounted = false;
        this.timer = null;
        this.state = {
            ad: {
                adLink: Billboard,
            },
            isComponentVisible: false,
            viewed: {},
        };
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    componentWillUnmount() {
        this.timer && clearInterval(this.timer);
        this.updateVisibleAds(false)
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.sendBillBoard([])
    }

    componentDidUpdate(prevProps, prevState) {
        const page = abecPages.getPageName(this.props.match.path)
        const isComponentVisible = this.state.isComponentVisible !== prevState.isComponentVisible && this.state.isComponentVisible;
        if (isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen && this.isEmpty(this.currentAd)) {
            if (page === "ListingPage") {
                this.getListingAds()
            } else if (page === "LookupPage") {
                this.getAdsBySearch()
            } else {
                this.getAds(page)
            }
        }

        const visiblityPropChanged = this.state.isComponentVisible !== prevState.isComponentVisible || this.props.isPageVisible !== prevProps.isPageVisible || this.props.isPopupOpen !== prevProps.isPopupOpen
        if (this.state.ad.id && visiblityPropChanged) {
            if (this.state.isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen)
                this.startTimer()
            else
                this.stopTimer()
        }
    }

    getListingAds = () => {
        this.setState({
            ad: {
                adLink: Billboard
            }
        })
        if (this.props.search_data.loadAds)
            adService.getAdsByListing(this.props.search_data, "billBoard").then(response => {
                if (this.state.isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen && this.isEmpty(this.currentAd))
                    if (this.props.viewedAds.length < 135) {
                        this.showAdvertisement(response)
                        this.updateAdDisplay(this.state.ad.id)
                    }
            })
        else {
            if (this.timer)
                this.stopTimer();
            this.currentAd = {};
        }
    }

    getAdsBySearch = () => {
        this.setState({
            ad: {
                adLink: Billboard
            }
        })
        adService.getAdsBySearch(this.props.search_data, 'billBoard').then(response => {
            if (this.state.isComponentVisible && this.props.isPageVisible && this.isEmpty(this.currentAd)) {
                if (this.props.viewedAds.length < 135) {
                    this.showAdvertisement(response)
                    this.updateAdDisplay(this.state.ad.id)
                }
            }
        })
    }

    getAds = (page) => {
        this.setState({
            ad: {
                adLink: Billboard
            }
        })
        adService.getAdvrtsByPageTypeAndLocation(this.props.current_coords, page, 'billBoard')
            .then(response => {
                if (this.state.isComponentVisible && this.props.isPageVisible && this.isEmpty(this.currentAd))
                    if (this.props.viewedAds.length < 135) {
                        this.showAdvertisement(response)
                        this.updateAdDisplay(this.state.ad.id)
                    }
            })
    }

    showAdvertisement = (ads) => {
        const storedAd = JSON.parse(sessionStorage.getItem('billBoard' + this.props.spot))

        const pageName = abecPages.getPageName(this.props.match.path);
        this.props.storedBillBoard.forEach(e => {
            const ad = JSON.parse(sessionStorage.getItem(e));
            if (ad && ad.page !== pageName)
                sessionStorage.removeItem(e);
        })
        if (storedAd && storedAd.page === abecPages.getPageName(this.props.match.path)) {
            //sessionStorage.setItem(key, JSON.stringify([...this.props.storedBillBoard]))
            this.setState({ ad: storedAd, viewed: { [storedAd.id]: this.props.viewedAds.includes(storedAd.id + ":billBoard") } })
            this.seconds = 30;

        } else if (!this.isEmpty(ads[0]) || ads[0]) {
            this.currentAd = ads.splice(0, 1)[0];
            this.currentAd.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd.advertisement;
            let loadedBillBoard = [...this.props.billBoardAds]
            if (!loadedBillBoard.includes(this.currentAd.id) && !this.props.viewedAds.includes(this.currentAd.id + ":billBoard")) {
                this._isMounted && this.setState({ ad: this.currentAd })
                loadedBillBoard.push(this.currentAd.id)
                this.props.sendBillBoard(loadedBillBoard)
                sessionStorage.removeItem('billBoard' + this.props.spot)
            } else if (this.state.ad.id && this.seconds < MAX_AD_TIME) {
                this._isMounted && this.setState({ ad: this.currentAd })
                loadedBillBoard.push(this.currentAd.id)
                this.props.sendBillBoard(loadedBillBoard)
                sessionStorage.removeItem('billboard' + this.props.spot)
            }
            else
                this.showAdvertisement(ads);
            this.seconds = 0;
            this.startTimer()
            sessionStorage.removeItem('billBoard' + this.props.spot)
        } else if (!this.state.ad.id) {
            sessionStorage.removeItem('billBoard' + this.props.spot)
            this._isMounted && this.setState({
                ad: {
                    adLink: Billboard
                }
            })
        }
    }

    startTimer = () => {
        if (this.timer)
            this.stopTimer();
        if (this.currentAd.id && this.seconds <= MAX_AD_TIME) {
            this.timer = setInterval(() => {
                this.seconds += 1;
                if (this.seconds > MAX_AD_TIME) {
                    this.updateBbViews(this.state.ad.id);
                    this.stopTimer();
                    this.currentAd.page = abecPages.getPageName(this.props.match.path)
                    const key = "billBoard" + this.props.spot;
                    sessionStorage.setItem(key, JSON.stringify(this.currentAd))
                    const storedAds = [...this.props.storedBillBoard]
                    storedAds.push(key)
                    this.props.setStoredBBAds(storedAds);
                }
            }, 1000);
        }
    }

    stopTimer = () => {
        clearInterval(this.timer);
    }

    updateVisibleAds = (isComponentVisible) => {
        const adName = "billBoard" + this.props.spot
        if (isComponentVisible && !this.props.visibleAds.includes(adName)) {
            this.props.setVisibleAds([...this.props.visibleAds, adName])
        } else if (!isComponentVisible && this.props.visibleAds.includes(adName)) {
            this.props.setVisibleAds(this.props.visibleAds.filter(e => e !== adName))
        }
    }

    updateAdDisplay = (id) => {
        let listingData = {
            viewedOnListing: null,
            section: null,
        }
        if (this.props.search_data.listingId) {
            listingData = {
                viewedOnListing: parseInt(this.props.search_data.listingId),
                section: this.props.search_data.place,
            }
        }
        adService.updateAdDisplay(id, 'billBoard', listingData).then(result => {
            this._isMounted && this.setState({ section: { [id]: result } })
        })
    }

    updateBbViews = (id) => {
        let listingData = {
            viewedOnListing: null,
            viewed: null,
            section: null,
        }
        if (this.props.search_data.listingId) {
            listingData = {
                viewedOnListing: parseInt(this.props.search_data.listingId),
                viewed: this.props.search_data.isViewed,
                section: this.props.search_data.place,
            }
        }
        adService.updateBbViews(id, 'billBoard', listingData).then(result => {
            this._isMounted && this.setState({ viewed: { [id]: result } })
            this.props.sendViewedAds(id + ":billBoard");
        })

    }

    render() {
        return (
            <VizSensor
                onChange={(isVisible) => {
                    if (this.state.isComponentVisible !== isVisible) {
                        this._isMounted && this.setState({ isComponentVisible: isVisible })
                        this._isMounted && this.updateVisibleAds(isVisible)
                    }
                }}
                partialVisibility
                offset={
                    { top: 300, bottom: 125 }
                }
            >
                <div className={`${styles.BillBoard_Ad}`}>
                    <div className={`${styles.ad_position}`}>
                        <strong className="ad_mark">AD</strong>
                    </div>
                    {(!this.isEmpty(this.state.ad) && this.state.ad.mediaType === 'video')
                        ?
                        <video className={`img-fluid ${this.state.ad.website ? 'cursor_pointer' : ''}`}
                            onClick={() => adService.redirect(this.state.ad.website)}
                            autoPlay={true} loop src={this.state.ad.adLink} type="video/mp4" onLoad={this.handleImageLoad} muted>
                        </video>
                        :
                        <img className={`img-fluid ${this.state.ad.website ? 'cursor_pointer' : ''}`} id={"bill-board" + this.props.spot} loading='lazy' width='970' height='250'
                            onClick={() => adService.redirect(this.state.ad.website)}
                            src={this.state.ad.adLink} alt="Bill Board Ad" />
                    }
                    <div className={`${styles.ad_position}`}>
                        {(this.state.viewed[this.state.ad.id]) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                    </div>
                </div>
            </VizSensor>
        )
    }
}

const mapStateToProps = state => {
    return {
        billBoardAds: state.billBoardAds,
        isPopupOpen: state.isPopupOpen,
        current_coords: state.current_coords,
        search_data: state.search_data,
        isPageVisible: state.isPageVisible,
        viewedAds: state.viewedAds,
        visibleAds: state.visibleAds,
        storedBillBoard: state.storedBillBoard,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendBillBoard: (billBoardAds) => dispatch({ type: 'BILLBOARD_ADS', billBoardAds: billBoardAds }),
        sendViewedAds: (viewedAds) => dispatch({ type: 'VIEWED_ADS', viewedAds: viewedAds }),
        setVisibleAds: (visibleAds) => dispatch({ type: 'VISIBLE_ADS', visibleAds: visibleAds }),
        setStoredBBAds: (storedBillBoard) => dispatch({ type: 'STORED_BB_ADS', storedBillBoard: storedBillBoard })
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillBoard));
