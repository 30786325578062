import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MAX_AD_TIME } from '../services/ABeCProperties'
import adService from './service/adService';
import styles from './styles/advertise.module.css';
import leaderboard from '../../assets/leaderboard.svg'

/**
 * This component renders one Leader Board Ad (728px x 90px)
 * 
 * this component is mounted in Header Component so as long as header is not reloaded Leader board ad component will not be remounted
 * 
 * every time there is change in page it looks up for the ads for that page
 *   if there are no ad currently being shown and there are ads in the fetch response it start showing ad
 *   
 *   if there is any ad already being shown and it has completed it max time defined in MAX_AD_TIME in ABeCProperties
 *   then it start showing newly fetched ads if there are any else it will reset to filler ad 
 *     www.files.aajubaaju.in/assets/Leaderboard.jpg
 * 
 *   if there is any ad already being shown and it has time remaing to complete 
 *     if there is any ad in fetch result will be kept in next ad variable and will start showing once current ad reaches time limit
 *     if there are no ads in fetch result once current ad reaches time limit then it will be reset to filler ad
 * 
 * once ad reaches MAX_AD_TIME it calls an api to update view count and do futher processing
 * 
 * Timer will stop if web page tab is not active or not in focus on browser window or popup ad is open
 * 
 * Component requireds props 
 *   current_coords (current geo cordinates of the user)
 *   search_data
 *       when on listing page setting such ad categories and location for the listing will be used as search data
 *       on lookup page any search query added by user will be used as search data
 *   content_page 
 *      an string returned from abecPages based on current url
 *   isPopupOpen (to determine wether or not popup ad is open)
 *   isPageVisible (to determine wether or not web page is visible)
 */

class HeaderLeaderBoard extends Component {

    constructor(props) {
        super(props);
        this.currentAd = {};
        this.nextAd = {};
        this.seconds = 0;
        this.leaderBoardTimer = null;
        this.state = {
            ad: {
                adLink: leaderboard,
            },
            viewed: {},
        };
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    componentDidMount() {
        this.updateVisibleAds()
    }

    componentWillUnmount() {
        this.updateVisibleAds()
        clearInterval(this.leaderBoardTimer);
        //this.seconds = 0;
    }

    updateVisibleAds = () => {
        const adName = "leaderBoard"
        if (!this.props.visibleAds.includes(adName)) {
            this.props.setVisibleAds([...this.props.visibleAds, adName])
        } else if (this.props.visibleAds.includes(adName)) {
            this.props.setVisibleAds(this.props.visibleAds.filter(e => e !== adName))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.content_page === "ListingPage") {
            if (this.props.search_data !== prevProps.search_data)
                this.getListingAds()
        } else if (this.props.content_page === "LookupPage") {
            if (this.props.search_data !== prevProps.search_data)
                this.getAdsBySearch()
        } else if (this.props.content_page !== prevProps.content_page) {
            this.getAds()
        }

        const visiblityPropChanged = this.props.isPageVisible !== prevProps.isPageVisible || this.props.isPopupOpen !== prevProps.isPopupOpen
        if (this.state.ad.id && visiblityPropChanged) {
            if (this.props.isPageVisible && !this.props.isPopupOpen)
                this.startTimer()
            else
                this.stopTimer()
        }
    }

    getListingAds = () => {
        this.setState({
            ad: {
                adLink: leaderboard
            }
        })
        if (this.props.search_data.loadAds)
            adService.getAdsByListing(this.props.search_data, "leaderBoard").then(response => {
                if (this.props.viewedAds.length < 135) {
                    this.showAdvertisement(response)
                    this.updateAdDisplay(this.state.ad.id)
                }
            })
        else {
            if (this.leaderBoardTimer)
                this.stopTimer();
            this.currentAd = {};
        }
    }

    getAdsBySearch = () => {
        this.setState({
            ad: {
                adLink: leaderboard
            }
        })
        adService.getAdsBySearch(this.props.search_data, 'leaderBoard').then(response => {
            if (this.props.viewedAds.length < 135) {
                this.showAdvertisement(response)
                this.updateAdDisplay(this.state.ad.id)
            }
        })
    }

    // getAds = () => {
    //     // adService.getAdvrtsByPageTypeAndLocation(this.props.current_coords,this.props.search_data, this.props.content_page, 'leaderBoard')
    //     this.setState({
    //         ad: {
    //             adLink: process.env.REACT_APP_IMAGES_URL + 'assets/Leaderboard.jpg'
    //         }
    //     })
    //     adService.getAdvrtsByPageTypeAndLocation(this.props.current_coords, this.props.content_page, 'leaderBoard')
    //         .then(response => {
    //             this.showAdvertisement(response)
    //         })
    // }

    getAds = () => {
        this.setState({
            ad: {
                adLink: leaderboard
            }
        })
        adService.getAdvrtsByPageTypeAndLocation(this.props.current_coords, this.props.content_page, 'leaderBoard')
            .then(response => {
                if (this.props.viewedAds.length < 135) {
                    this.showAdvertisement(response)
                    this.updateAdDisplay(this.state.ad.id)
                }
            })
    }

    showAdvertisement = (ads) => {

        const storedAd = JSON.parse(sessionStorage.getItem('leaderBoard'))

        if (storedAd && storedAd.page === this.props.content_page) {
            this.setState({ ad: storedAd, viewed: { [storedAd.id]: this.props.viewedAds.includes(storedAd.id + ":leaderBoard") } })
            this.seconds = 30;
        } else if (!this.isEmpty(ads[0]) || ads[0]) {
            this.nextAd = ads.splice(0, 1)[0];
            if (this.props.viewedAds.includes(this.nextAd.id + ":leaderBoard"))
                this.showAdvertisement(ads) // if next ad is already viewed (works for user own ad)
            else if (this.state.ad.id && this.seconds < MAX_AD_TIME) {
                this.currentAd = { ...this.nextAd };
                this.currentAd.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd.advertisement;
                this.setState({ ad: this.currentAd })
                this.currentAd.page = this.props.content_page
                sessionStorage.setItem("leaderBoard", JSON.stringify(this.currentAd))
                this.seconds = 0;
                this.nextAd = {};
                if (this.state.ad.id === this.nextAd.id)
                    this.showAdvertisement(ads) // if next ad in the list ad current ad is same look for another ad
                else
                    this.nextAd.adLink = process.env.REACT_APP_IMAGES_URL + this.nextAd.advertisement;
            } else {
                this.currentAd = { ...this.nextAd };
                this.currentAd.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd.advertisement;
                this.setState({ ad: this.currentAd })
                this.currentAd.page = this.props.content_page
                sessionStorage.setItem("leaderBoard", JSON.stringify(this.currentAd))
                this.seconds = 0;
                this.nextAd = {};
            }
            this.startTimer();
        } else if (!this.state.ad.id || this.seconds >= MAX_AD_TIME) {
            this.setState({
                ad: { adLink: leaderboard }
            })
            sessionStorage.removeItem('leaderBoard')
        } else {
            this.nextAd = null
        }
    }

    startTimer = () => {
        if (this.leaderBoardTimer)
            this.stopTimer();
        if (this.currentAd.id && this.seconds <= MAX_AD_TIME) {
            if (this.nextAd === null) {
                this.currentAd = {}
                this.setState({
                    ad: { adLink: leaderboard }
                })
            }
            else this.leaderBoardTimer = setInterval(() => {
                this.seconds += 1;
                if (this.seconds > MAX_AD_TIME) {
                    this.stopTimer();
                    this.updateLbViews(this.state.ad.id);
                }
                // else if (this.nextAd.id) {
                //     this.currentAd = { ...this.nextAd };
                //     this.setState({ ad: this.currentAd })
                //     this.currentAd.page = this.props.content_page
                //     sessionStorage.setItem("leaderBoard", JSON.stringify(this.currentAd))
                //     this.nextAd = {};
                //     this.seconds = 2;
                //     this.startTimer();
                // }
            }, 1000);
        }
    }

    stopTimer = () => {
        clearInterval(this.leaderBoardTimer);
    }

    updateAdDisplay = (id) => {
        let listingData = {
            viewedOnListing: null,
            section: null,
        }
        if (this.props.search_data.listingId) {
            listingData = {
                viewedOnListing: parseInt(this.props.search_data.listingId),
                section: this.props.search_data.place,
            }
        }
        adService.updateAdDisplay(id, 'leaderBoard', listingData).then(result => {
            this.setState({ section: { [id]: result } })
        })
    }

    updateLbViews = (id) => {
        let listingData = {
            viewedOnListing: null,
            viewed: null,
            section: null,
        }
        if (this.props.search_data.listingId) {
            listingData = {
                viewedOnListing: parseInt(this.props.search_data.listingId),
                viewed: this.props.search_data.isViewed,
                section: this.props.search_data.place,
            }
        }
        adService.updateLbViews(id, 'leaderBoard', listingData).then(result => {
            this.setState({ viewed: { [id]: result } })
            this.props.sendViewedAds(id + ":leaderBoard");
        })

    }

    render() {
        return (
            <div className={`${styles.LeaderBoard_Add}`}>
                <div className={`${styles.ad_position}`}>
                    <strong className="ad_mark">AD</strong>
                </div>{console.log(this.state.ad.mediaType)}

                {(!this.isEmpty(this.state.ad) && this.state.ad.mediaType === 'video')
                    ?
                    <video className={`img-fluid ${this.state.ad.website ? 'cursor_pointer' : ''}`}
                        onClick={() => adService.redirect(this.state.ad.website)}
                        autoPlay={true} loop src={this.state.ad.adLink} type="video/mp4" onLoad={this.handleImageLoad} muted>
                    </video>
                    :
                    <img className={`img-fluid ${this.state.ad.website ? 'cursor_pointer' : ''}`} id="leaderboard-ad" loading='lazy' width='728' height='90'
                        onClick={() => adService.redirect(this.state.ad.website)}
                        src={this.state.ad.adLink} alt="LeaderBoard Banner Ad" />
                }
                <div className={`${styles.ad_position}`}>
                    {(this.state.viewed[this.state.ad.id]) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        current_coords: state.current_coords,
        content_page: state.content_page,
        isPopupOpen: state.isPopupOpen,
        search_data: state.search_data,
        isPageVisible: state.isPageVisible,
        viewedAds: state.viewedAds,
        visibleAds: state.visibleAds,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendViewedAds: (viewedAds) => dispatch({ type: 'VIEWED_ADS', viewedAds: viewedAds }),
        setVisibleAds: (visibleAds) => dispatch({ type: 'VISIBLE_ADS', visibleAds: visibleAds }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderLeaderBoard));